// extracted by mini-css-extract-plugin
export var Golang = "FullStackCombination-module--Golang--ca5e4";
export var angularCombination = "FullStackCombination-module--angularCombination--b344d";
export var bannerBtnGolangBook = "FullStackCombination-module--bannerBtnGolangBook--43346";
export var btn_white2_border_book_Goolang_dev = "FullStackCombination-module--btn_white2_border_book_Goolang_dev--b0910";
export var card = "FullStackCombination-module--card--ee407";
export var cardWrapper = "FullStackCombination-module--cardWrapper--41a5f";
export var description = "FullStackCombination-module--description--3240c";
export var heading = "FullStackCombination-module--heading--600c5";
export var imgs = "FullStackCombination-module--imgs--6ffa0";
export var javaComDec = "FullStackCombination-module--javaComDec--087c2";
export var javaComHeading = "FullStackCombination-module--javaComHeading--ddb08";