import React from "react"
import * as styles from "./WhyHireAngular.module.scss"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"

const WhyHireAngular = ({ strapiData, whyHire, whyHireDec }) => {
  return (
    <React.Fragment>
      <div
        id="devopsServices"
        className={`${styles.ServicesWhyAngular} ${
          whyHire ? styles.whyHirePadd : ""
        }`}
      >
        <Container>
          <h2
            className={`${styles.heading}`}
            dangerouslySetInnerHTML={{ __html: strapiData?.title }}
          />
          <div
            className={`${styles.description} ${
              whyHireDec ? styles.whyHire : ""
            }`}
            dangerouslySetInnerHTML={{
              __html: strapiData?.subTitle,
            }}
          />
          <Row className={`gap-30 justify-content-start ${styles.cardWrapper}`}>
            {strapiData?.cards?.map((e, i) => (
              <Col xl={4} md={6} xs={12} key={i} className="gap-30 d-flex">
                <div className={styles.card}>
                  <lottie-player
                    autoplay
                    loop
                    src={e?.image1[0]?.localFile?.publicURL}
                    style={{ height: "60px", width: "60px", margin: "0" }}
                  />
                  <h3>{e?.title}</h3>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: e?.subTitle,
                    }}
                  />
                </div>
              </Col>
            ))}
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default WhyHireAngular
