import { Link } from "gatsby"
import React from "react"
import * as styles from "./QuickReads.module.scss"
import { Container, Row, Col } from "react-bootstrap"
const QuickReads = ({ strapiData, androidBlog }) => {
  return (
    <React.Fragment>
      <div className={styles.quickReads}>
        <Container>
          <h2
            className={`${styles.heading} ${
              androidBlog ? styles.androidBlogHeading : ""
            }`}
            dangerouslySetInnerHTML={{ __html: strapiData?.title }}
          />
          <p
            className={styles.bannerSubtitle2}
            dangerouslySetInnerHTML={{
              __html: strapiData?.subTitle,
            }}
          />
          <Row className={`${styles.cardWrapper} gap-30`}>
            {strapiData?.cards?.map((e, i) => (
              <Col xl={4} md={6} xs={12} key={i} className="gap-30 d-flex">
                <div className={styles.card}>
                  <Link to={e?.buttons[0]?.url}>
                    <img
                      className={styles.blogimg}
                      decoding="async"
                      loading="lazy"
                      src={e?.image1 && e?.image1[0]?.localFile?.publicURL}
                      alt={e?.title2}
                    />
                  </Link>

                  <div
                    className={`${`d-flex justify-content-between`} ${
                      styles.topDetails
                    }`}
                  >
                    <Link to={e?.buttons[2]?.url}>
                      <p className={styles.category}>{e?.title2}</p>
                    </Link>

                    <div
                      className={`d-flex align-items-center ${styles.writer}`}
                    >
                      <div>
                        <Link to={e?.buttons[1]?.url}>
                          <p
                            className={styles.writerName}
                            dangerouslySetInnerHTML={{
                              __html: e?.subTitle,
                            }}
                          />
                        </Link>
                      </div>
                      <div>
                        <p className={styles.minRead}>{e?.subTitle2}</p>
                      </div>
                    </div>
                  </div>
                  <Link to={e?.buttons[0]?.url}>
                    <h3>{e?.title}</h3>
                  </Link>

                  <p
                    className={styles.blogDesc}
                    dangerouslySetInnerHTML={{
                      __html: e?.description?.description,
                    }}
                  />
                </div>
              </Col>
            ))}
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default QuickReads
