// extracted by mini-css-extract-plugin
export var ServicesWhyAngular = "WhyHireAngular-module--ServicesWhyAngular--8c288";
export var card = "WhyHireAngular-module--card--5b931";
export var cardWrapper = "WhyHireAngular-module--cardWrapper--a71a2";
export var description = "WhyHireAngular-module--description--5340a";
export var heading = "WhyHireAngular-module--heading--6a773";
export var loader = "WhyHireAngular-module--loader--5bb6c";
export var lodaMoreBtn = "WhyHireAngular-module--lodaMoreBtn--1cdec";
export var lodalessBtn = "WhyHireAngular-module--lodalessBtn--caf20";
export var showlessdiv = "WhyHireAngular-module--showlessdiv--1c616";
export var spin = "WhyHireAngular-module--spin--2b1db";
export var trailBg = "WhyHireAngular-module--trailBg--4b4db";
export var whyHire = "WhyHireAngular-module--whyHire--9bf32";
export var whyHirePadd = "WhyHireAngular-module--whyHirePadd--7fc4d";