// extracted by mini-css-extract-plugin
export var androidBlogHeading = "QuickReads-module--androidBlogHeading--1ab3d";
export var bannerSubtitle2 = "QuickReads-module--bannerSubtitle2--56849";
export var blogDesc = "QuickReads-module--blogDesc--33e9c";
export var blogimg = "QuickReads-module--blogimg--335d7";
export var card = "QuickReads-module--card--a63a2";
export var cardWrapper = "QuickReads-module--cardWrapper--c35c2";
export var category = "QuickReads-module--category--5db58";
export var heading = "QuickReads-module--heading--0d428";
export var minRead = "QuickReads-module--minRead--52d96";
export var quickReads = "QuickReads-module--quickReads--a7bda";
export var topDetails = "QuickReads-module--topDetails--614fc";
export var writer = "QuickReads-module--writer--6e473";
export var writerName = "QuickReads-module--writerName--81412";